<template>
    <v-container fluid>
        <v-form v-model="isFormValid">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                {{ title }}
                            </v-col>
                        </v-row>

                        <parameters v-model="parameters" />

                        <v-row>
                            <v-col>
                                <v-btn
                                    :disabled="!isFormValid"
                                    @click="runQuery">
                                    Build Report
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>

                <v-card-text>
                    <report-table
                        :items="items"
                        :headers="headers"
                        :is-query-loading="isQueryLoading"
                        :title="title" />
                </v-card-text>
            </v-card>
        </v-form>
    </v-container>
</template>

<script>
import axios from 'axios'
import { handleError } from "@/services/errorUtility";

export default {
    components: {
        reportTable: () => import('../reports/ReportTable'),
        parameters: () => import('../reports/Parameters')
    },

    data() {
        return {
            isQueryLoading: false,

            headers: [
                { text: "Test Group", value: "testGroup" },
                { text: "Test", value: "test" },
                { text: "Number of Samples", value: "numberOfSamples" },
                { text: "Average Result", value: "averageResult" }
            ],
            items: [],
            parameters: [
                {
                    component: () => import('./AutocompleteParameter'),
                    field: {
                        label: "Sample Set",
                        property: "sampleSetId",
                        pascalPlural: 'SampleSets'
                    }
                }
            ],
            title: "Sample Set Test Report",

            isFormValid: false
        }
    },
    watch: {
        parameters: {
            deep: true,
            handler() {
                if (this.parameters.every(e => !this.isEmpty(e.field.value))) {
                    this.isFormValid = true;
                }
            }
        }
    },
    methods: {
        async runQuery() {
            this.isQueryLoading = true;
            try {
                let sampleSetId = this.parameters[0].field.value;
                let response = await axios.get('/api/SampleSetTestReport/' + sampleSetId);
                this.items = response.data;
            }
            catch (error) {
                handleError(error);
            }
            finally {
                this.isQueryLoading = false;
            }
        },
    }
}
</script>
